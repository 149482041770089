import { versendungsartenTranslateKey } from "./fristenrechnerTypes";

export const versendungsarten: [
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string }
] = [
  { value: "einschreiben", label: "Einschreiben" },
  { value: "post", label: "Einfache Post" },
  { value: "email", label: "Email" },
  { value: "fax", label: "Fax" },
];

export const bemessungszeiträume = [
  { value: 0, label: "Tage", bemessungsvalue: 1 },
  { value: 33, label: "Wochen", bemessungsvalue: 2 },
  { value: 66, label: "Monate", bemessungsvalue: 3 },
  { value: 100, label: "Quartale oder länger", bemessungsvalue: 4 },
];

export const fristenp621BGB = {
  p621Nr1:
    "Kündigung zulässig an jedem Tag für den Ablauf des folgenden Tages (§ 621 Nr. 1 BGB)",
  p621Nr2:
    "Kündigung zulässig spätestens am ersten Werktag einer Woche für den Ablauf des folgenden Sonnabend  (§ 621 Nr. 2 BGB)",
  p621Nr3:
    "Kündigung zulässig spätestens am 15. eines Monats für den Schluss des Kalendermonats  (§ 621 Nr. 3 BGB)",
  p621Nr4:
    "Kündigung zulässig unter Einhaltung einer Kündigungsfrist von sechs Wochen für den Schluss eines Kalendervierteljahrs  (§ 621 Nr. 4 BGB)",
};

export enum Quartale {
  Q1 = "q1",
  Q2 = "q2",
  Q3 = "q3",
  Q4 = "q4",
}

export const numbersDV = {
  [Quartale.Q1]: {
    quartal: 1,
    letzterZugangstag: 17,
    letzterZugangstagSchaltjahr: 18,
    letzterZugangstagSchaltjahrCorrection: true,
    letzterZugangstagMonat: 1, // Index
    ersterZugangstag: 20,
    ersterZugangstagSchaltjahr: 20,
    ersterZugangstagSchaltjahrCorrection: false,
    ersterZugangstagMonat: 10, // Index
    wirksamwerdenMitAblaufTag: 31,
    wirksamwerdenMitAblaufMonat: 2, // Index
  },
  [Quartale.Q2]: {
    quartal: 2,
    letzterZugangstag: 19,
    letzterZugangstagSchaltjahr: 19,
    letzterZugangstagSchaltjahrCorrection: false,
    letzterZugangstagMonat: 4, // Index
    ersterZugangstag: 18,
    ersterZugangstagSchaltjahr: 19,
    ersterZugangstagSchaltjahrCorrection: true,
    ersterZugangstagMonat: 1, // Index
    wirksamwerdenMitAblaufTag: 30,
    wirksamwerdenMitAblaufMonat: 6, // Index
  },
  [Quartale.Q3]: {
    quartal: 3,
    letzterZugangstag: 19,
    letzterZugangstagSchaltjahr: 19,
    letzterZugangstagSchaltjahrCorrection: false,
    letzterZugangstagMonat: 7, // Index
    ersterZugangstag: 20,
    ersterZugangstagSchaltjahr: 20,
    ersterZugangstagSchaltjahrCorrection: false,
    ersterZugangstagMonat: 4, // Index
    wirksamwerdenMitAblaufTag: 30,
    wirksamwerdenMitAblaufMonat: 8, // Index
  },
  [Quartale.Q4]: {
    quartal: 4,
    letzterZugangstag: 19,
    letzterZugangstagSchaltjahr: 19,
    letzterZugangstagSchaltjahrCorrection: false,
    letzterZugangstagMonat: 10, // Index
    ersterZugangstag: 20,
    ersterZugangstagSchaltjahr: 20,
    ersterZugangstagSchaltjahrCorrection: false,
    ersterZugangstagMonat: 7, // Index
    wirksamwerdenMitAblaufTag: 31,
    wirksamwerdenMitAblaufMonat: 11, // Index
  },
};

export const bestehenszeiträume = [
  { value: 0, label: "< 2", bestehensvalue: 0 },
  { value: 10, label: "2", bestehensvalue: 2 },
  { value: 25, label: "5", bestehensvalue: 5 },
  { value: 40, label: "8", bestehensvalue: 8 },
  { value: 50, label: "10", bestehensvalue: 10 },
  { value: 60, label: "12", bestehensvalue: 12 },
  { value: 75, label: "15", bestehensvalue: 15 },
  { value: 100, label: "20", bestehensvalue: 20 },
];

export const fristenp622BGB = {
  p622Abs1: {
    text: "Kündigung zulässig mit einer Frist von vier Wochen zum Fünfzehnten oder zum Ende eines Kalendermonats gekündigt werden (§ 622 Abs. 1 BGB).",
    bestehensvalue: 0,
    frist: 0,
  },
  p622Abs2Nr1: {
    text: "Kündigung zulässig mit einer Frist von einen Monat zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 1 BGB)",
    bestehensvalue: 2,
    frist: 1,
  },
  p622Abs2Nr2: {
    text: "Kündigung zulässig mit einer Frist von zwei Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 2 BGB)",
    bestehensvalue: 5,
    frist: 2,
  },
  p622Abs2Nr3: {
    text: "Kündigung zulässig mit einer Frist von drei Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 3 BGB)",
    bestehensvalue: 8,
    frist: 3,
  },
  p622Abs2Nr4: {
    text: "Kündigung zulässig mit einer Frist von vier Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 4 BGB)",
    bestehensvalue: 10,
    frist: 4,
  },
  p622Abs2Nr5: {
    text: "Kündigung zulässig mit einer Frist von fünf Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 5 BGB)",
    bestehensvalue: 12,
    frist: 5,
  },
  p622Abs2Nr6: {
    text: "Kündigung zulässig mit einer Frist von sechs Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 6 BGB)",
    bestehensvalue: 15,
    frist: 6,
  },
  p622Abs2Nr7: {
    text: "Kündigung zulässig mit einer Frist von sieben Monaten zum Ende eines Kalendermonats (§ 622 Abs. 2 Nr. 7 BGB)",
    bestehensvalue: 20,
    frist: 7,
  },
};

export const einberufungswochenTranslate: {
  [key: number]: { number: number; string: string };
} = {
  0: { number: 1, string: "1 Woche" },
  33: { number: 2, string: "2 Wochen" },
  66: { number: 3, string: "3 Woche" },
  100: { number: 4, string: "4 Woche" },
};

export const versendungsartenTranslate: {
  einschreiben: string;
  post: string;
  email: string;
  fax: string;
} = {
  einschreiben: "Einschreiben",
  post: "Einfache Post",
  email: "Email",
  fax: "Fax",
};

export const postlaufzeiten = {
  email: 1,
  postOderEinschreibenInland: 2,
  postOderEinschreibenAusland: 4,
};
