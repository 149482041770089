/* eslint-disable no-nested-ternary */
import { Grid, Paper, Typography } from "@material-ui/core";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from "@mui/x-date-pickers/LocalizationProvider";

import { useBooleanState } from "@nvon/react-toolbox";
import {
  addMonths,
  addWeeks,
  closestTo,
  endOfMonth,
  getDate,
  getYear,
  isWithinInterval,
  setDate,
} from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import { t } from "i18next";
import { useState } from "react";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import {
  bestehenszeiträume,
  numbersDV,
  Quartale,
} from "./fristenrechnerConstants";
import {
  dateErsterZugangstag,
  dateErsterZugangstagCorrected,
  dateLetzterZugangstagCorrected,
} from "./fristenrechnerHelperFunctions/dateCalculations";
import FirstQuestion from "./FristenrechnerQuestions/FirstQuestion";
import SecondQuestion from "./FristenrechnerQuestions/SecondQuestion";
import ErgebnisTimeline from "./FristenrechnerResult/ErgebnisTimeline";
import { useFristenrechnerStyles } from "./FristenrechnerStyles";
import ButtonVersendungstagVersammlungstag from "./FristenrechnerSwitch/ButtonVersendungstagVersammlungstag";

const FristenrechnerKündigungGfContent = (): JSX.Element => {
  const classes = useFristenrechnerStyles();

  /**
   * As opposed to counting from day of Versendung
   */
  const {
    state: countingFromDayOfVersammlung,
    on: setToCountingFromVersammlung,
    off: setToCountingFromVersendung,
  } = useBooleanState(false);

  const [chosenKündigungZugangstag, setChosenKündigungZugangstag] =
    useState<Date>(new Date());

  const [jahresgehaltJaNein, setJahresgehaltJaNein] = useState<string>("ja");

  const [vergütungBemessungszeitraum, setVergütungBemessungszeitraum] =
    useState<number | number[]>(66);

  const [
    arbeitsverhältnisBestehenZeitraum,
    setArbeitsverhältnisBestehenZeitraum,
  ] = useState<number | number[]>(0);

  // Berechnung bei freiem Dienstvertrag

  const kündigungZugangJahr = getYear(chosenKündigungZugangstag);

  const kündigungZugangTag = getDate(chosenKündigungZugangstag);

  // Berechnung für "Jahresgehalt" oder "Quartale oder länger" 621 Nr. 4 BGB

  const relevantYearForSchaltjahrTest = isWithinInterval(
    chosenKündigungZugangstag,
    {
      start: dateErsterZugangstag(kündigungZugangJahr, Quartale.Q1),
      end: new Date(kündigungZugangJahr, 12, 31, 23, 59, 59),
    }
  )
    ? kündigungZugangJahr + 1
    : kündigungZugangJahr;

  let relevantQuartal;
  switch (true) {
    case isWithinInterval(chosenKündigungZugangstag, {
      start: dateErsterZugangstagCorrected(
        relevantYearForSchaltjahrTest - 1,
        Quartale.Q1
      ),
      end: dateLetzterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q1
      ),
    }):
      relevantQuartal = Quartale.Q1;
      break;
    case isWithinInterval(chosenKündigungZugangstag, {
      start: dateErsterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q2
      ),
      end: dateLetzterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q2
      ),
    }):
      relevantQuartal = Quartale.Q2;
      break;
    case isWithinInterval(chosenKündigungZugangstag, {
      start: dateErsterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q3
      ),
      end: dateLetzterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q3
      ),
    }):
      relevantQuartal = Quartale.Q3;
      break;
    case isWithinInterval(chosenKündigungZugangstag, {
      start: dateErsterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q4
      ),
      end: dateLetzterZugangstagCorrected(
        relevantYearForSchaltjahrTest,
        Quartale.Q4
      ),
    }):
      relevantQuartal = Quartale.Q4;
      break;
  }

  let dateWirksamwerdenMitAblauf621Nr4;
  switch (true) {
    case relevantQuartal === Quartale.Q1:
      dateWirksamwerdenMitAblauf621Nr4 = new Date(
        relevantYearForSchaltjahrTest,
        numbersDV.q1.wirksamwerdenMitAblaufMonat,
        numbersDV.q1.wirksamwerdenMitAblaufTag
      );
      break;
    case relevantQuartal === Quartale.Q2:
      dateWirksamwerdenMitAblauf621Nr4 = new Date(
        relevantYearForSchaltjahrTest,
        numbersDV.q2.wirksamwerdenMitAblaufMonat,
        numbersDV.q2.wirksamwerdenMitAblaufTag
      );
      break;
    case relevantQuartal === Quartale.Q3:
      dateWirksamwerdenMitAblauf621Nr4 = new Date(
        relevantYearForSchaltjahrTest,
        numbersDV.q3.wirksamwerdenMitAblaufMonat,
        numbersDV.q3.wirksamwerdenMitAblaufTag
      );
      break;
    case relevantQuartal === Quartale.Q4:
      dateWirksamwerdenMitAblauf621Nr4 = new Date(
        relevantYearForSchaltjahrTest,
        numbersDV.q4.wirksamwerdenMitAblaufMonat,
        numbersDV.q4.wirksamwerdenMitAblaufTag
      );
      break;
  }

  // Berechnung für "Monate" 621 Nr. 3 BGB

  const dateWirksamwerdenMitAblauf621Nr3 =
    kündigungZugangTag >= 15
      ? endOfMonth(chosenKündigungZugangstag)
      : endOfMonth(addMonths(chosenKündigungZugangstag, 1));

  // Zuweisung Kündigungsauswahl je nach ausgewählter DV-Vergütungslänge

  let dateWirksamwerdenMitAblaufDV;
  switch (true) {
    case jahresgehaltJaNein === "ja" || vergütungBemessungszeitraum === 100:
      dateWirksamwerdenMitAblaufDV = dateWirksamwerdenMitAblauf621Nr4;
      break;
    case jahresgehaltJaNein === "nein" && vergütungBemessungszeitraum === 66:
      dateWirksamwerdenMitAblaufDV = dateWirksamwerdenMitAblauf621Nr3;
      break;
    case jahresgehaltJaNein === "nein" && vergütungBemessungszeitraum === 33:
      dateWirksamwerdenMitAblaufDV = 0;
      break;
    case jahresgehaltJaNein === "nein" && vergütungBemessungszeitraum === 0:
      dateWirksamwerdenMitAblaufDV = 0;
      break;
  }

  // Berechnung AV-Kündigungsfrist

  const p622Abs1Options = {
    vierWochenZumFünfzehnten: setDate(
      addWeeks(chosenKündigungZugangstag, 4),
      15
    ),
    vierWochenZumEnde: endOfMonth(addWeeks(chosenKündigungZugangstag, 4)),
  };

  const dateWirksamwerdenMitAblauf622Abs1 = closestTo(
    chosenKündigungZugangstag,
    [
      p622Abs1Options.vierWochenZumEnde,
      p622Abs1Options.vierWochenZumFünfzehnten,
    ]
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr1 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 1)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr2 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 2)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr3 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 3)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr4 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 4)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr5 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 5)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr6 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 6)
  );
  const dateWirksamwerdenMitAblauf622Abs2Nr7 = endOfMonth(
    addMonths(chosenKündigungZugangstag, 7)
  );

  let dateWirksamwerdenMitAblaufAV;
  switch (true) {
    case bestehenszeiträume[0] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[0].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs1;
      break;
    case bestehenszeiträume[1] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[1].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr1;
      break;
    case bestehenszeiträume[2] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[2].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr2;
      break;
    case bestehenszeiträume[3] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[3].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr3;
      break;
    case bestehenszeiträume[4] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[4].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr4;
      break;
    case bestehenszeiträume[5] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[5].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr5;
      break;
    case bestehenszeiträume[6] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[6].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr6;
      break;
    case bestehenszeiträume[7] &&
      arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[7].value:
      dateWirksamwerdenMitAblaufAV = dateWirksamwerdenMitAblauf622Abs2Nr7;
      break;
  }

  const dateWirksamwerdenMitAblauf = countingFromDayOfVersammlung
    ? dateWirksamwerdenMitAblaufAV
    : dateWirksamwerdenMitAblaufDV;

  return (
    <>
      <RVOContainer
        paddingBottom={false}
        noPaddingTop={true}
        className={[classes.intro, classes.description].join(" ")}
      >
        <Typography>{`${t(
          "fristenrechnerKündigungGf.introText1"
        )}`}</Typography>

        <br />

        <Typography>{`${t(
          "fristenrechnerKündigungGf.introText2"
        )}`}</Typography>

        <br />
      </RVOContainer>

      <RVOContainer noPaddingTop={true} paddingBottom={true}>
        <LocalizationProvider
          dateAdapter={
            // 🚨 Heavy type assertion. Might cause trouble down the line.
            AdapterDateFns as unknown as LocalizationProviderProps["dateAdapter"]
          }
          locale={de}
        >
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} md={6} lg={7}>
              <Paper elevation={20} className={classes.fristenPaper}>
                <ErgebnisTimeline
                  buttonSwitch={countingFromDayOfVersammlung}
                  chosenKündigungZugangstag={chosenKündigungZugangstag}
                  dateWirksamwerdenMitAblauf={dateWirksamwerdenMitAblauf}
                  jahresgehaltJaNein={jahresgehaltJaNein}
                  vergütungBemessungszeitraum={vergütungBemessungszeitraum}
                  arbeitsverhältnisBestehenZeitraum={
                    arbeitsverhältnisBestehenZeitraum
                  }
                />
              </Paper>
            </Grid>

            <Grid item={true} xs={12} md={6} lg={5}>
              <ButtonVersendungstagVersammlungstag
                {...{
                  countingFromDayOfVersammlung,
                  setToCountingFromVersammlung,
                  setToCountingFromVersendung,
                }}
              />

              <FirstQuestion
                chosenKündigungZugangstag={chosenKündigungZugangstag}
                handleKündigungZugangstag={(newValue: Date | null) => {
                  setChosenKündigungZugangstag(newValue || new Date());
                }}
              />

              <SecondQuestion
                buttonSwitch={countingFromDayOfVersammlung}
                jahresgehaltJaNein={jahresgehaltJaNein}
                handleJahresgehaltJaNein={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setJahresgehaltJaNein(event.target.value);
                }}
                vergütungBemessungszeitraum={vergütungBemessungszeitraum}
                handleVergütungBemessungszeitraum={(
                  event,
                  newValue: number | number[]
                ) => {
                  setVergütungBemessungszeitraum(newValue);
                }}
                arbeitsverhältnisBestehenZeitraum={
                  arbeitsverhältnisBestehenZeitraum
                }
                handleArbeitsverhältnisBestehenZeitraum={(
                  event,
                  newValue: number | number[]
                ) => {
                  setArbeitsverhältnisBestehenZeitraum(newValue);
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </RVOContainer>
    </>
  );
};

export default FristenrechnerKündigungGfContent;
