import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import { RVOFaqSection } from "../03-organisms/RVOFaqSection/RVOFaqSection";
import RVORegisterTodayCtaSection from "../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import { RVOUrteileSection } from "../03-organisms/RVOUrteileSection/RVOUrteileSection";
import FristenrechnerKündigungGfContent from "../04-templates/FristenrechnerKündigungGf/FristenrechnerKündigungGfContent";
import { FristenrechnerKündigungGfPageDescription } from "../04-templates/FristenrechnerKündigungGf/FristenrechnerKündigungGfPageDescription";
import { FristenrechnerKündigungGfPageHeader } from "../04-templates/FristenrechnerKündigungGf/FristenrechnerKündigungGfPageHeader";

export const FristenrechnerKündigungGfPage = (): JSX.Element => {
  const fristKundigungGfPage = useSinglePageByDatabaseId(2761);

  const urteile = fristKundigungGfPage?.pageFields?.urteile;
  const faqs = fristKundigungGfPage?.pageFields?.faqs;

  return (
    <>
      <Seo post={fristKundigungGfPage} />

      <FristenrechnerKündigungGfPageHeader page={fristKundigungGfPage} />

      <FristenrechnerKündigungGfContent />

      <FristenrechnerKündigungGfPageDescription page={fristKundigungGfPage} />

      <RVORegisterTodayCtaSection />

      {faqs?.title && faqs?.questions && (
        <RVOFaqSection
          title={faqs?.title}
          questions={faqs?.questions as GatsbyTypes.WpQuestionFieldsFragment[]}
        />
      )}

      {urteile?.title && urteile?.questions && (
        <RVOUrteileSection
          title={urteile?.title}
          questions={
            urteile?.questions as GatsbyTypes.WpQuestionFieldsFragment[]
          }
        />
      )}
    </>
  );
};

export default FristenrechnerKündigungGfPage;
