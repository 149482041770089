/* eslint-disable i18next/no-literal-string */
import {
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const FirstQuestion = ({
  chosenKündigungZugangstag,
  handleKündigungZugangstag,
}: {
  chosenKündigungZugangstag: Date;
  handleKündigungZugangstag: (newValue: Date | null) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      <Typography className={classes.additionsWrapper}>
        <span>Wann ist die Kündigung dem Geschäftsführer zugegangen? </span>

        <Tooltip
          title={t(
            "fristenrechnerKündigungGf.tooltipFirstQuestionKündigungZugang"
          )}
        >
          <HelpOutline className={classes.tooltipIcon} />
        </Tooltip>
      </Typography>

      <div className={classes.form}>
        <MobileDatePicker
          mask="__.__.____"
          label="Zugang der Kündigung am"
          value={chosenKündigungZugangstag}
          onChange={handleKündigungZugangstag}
          renderInput={(params) => (
            <TextField
              fullWidth={true}
              className={classes.innerform}
              // 🚨 This type assertion is not accurate,
              //     neither is the implementation.
              //
              //    We use a MUI v4 TextField, combining it
              //     with a v5 MobilePicker.
              {...(params as TextFieldProps)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FirstQuestion;
