import {
  addDays,
  addWeeks,
  format,
  isLeapYear,
  subDays,
  subWeeks,
} from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import {
  numbersDV,
  postlaufzeiten,
  Quartale,
  versendungsarten,
} from "../fristenrechnerConstants";

export const schaltjahrCorrector = (
  year: number,
  quartal: Quartale
): number => {
  if (
    isLeapYear(year) &&
    numbersDV[quartal].ersterZugangstagSchaltjahrCorrection
  ) {
    return 1;
  } else {
    return 0;
  }
};

export const dateErsterZugangstag = (
  kündigungZugangJahr: number,
  quartal: Quartale
): Date =>
  new Date(
    kündigungZugangJahr,
    numbersDV[quartal].ersterZugangstagMonat,
    numbersDV[quartal].ersterZugangstag,
    0,
    0,
    0
  );

export const dateErsterZugangstagCorrected = (
  relevantYearForSchaltjahrTest: number,
  quartal: Quartale
): Date =>
  new Date(
    relevantYearForSchaltjahrTest,
    numbersDV[quartal].ersterZugangstagMonat,
    isLeapYear(new Date(relevantYearForSchaltjahrTest, 1, 1))
      ? numbersDV[quartal].ersterZugangstagSchaltjahr
      : numbersDV[quartal].ersterZugangstag,
    0,
    0,
    0
  );

export const dateLetzterZugangstagCorrected = (
  relevantYearForSchaltjahrTest: number,
  quartal: Quartale
): Date =>
  new Date(
    relevantYearForSchaltjahrTest,
    numbersDV[quartal].letzterZugangstagMonat,
    isLeapYear(new Date(relevantYearForSchaltjahrTest, 1, 1))
      ? numbersDV[quartal].letzterZugangstagSchaltjahr
      : numbersDV[quartal].letzterZugangstag,
    23,
    59,
    59
  );

export const formatVersammlungstag = (
  date: Date | number | undefined
): "" | string => {
  if (date === null || date === undefined) {
    return "";
  } else {
    return `${format(date, "EEEE", { locale: de })}, den ${format(
      date,
      "dd.MM.yyyy",
      { locale: de }
    )}`;
  }
};

export const formatDate = (date: Date | "" | null): "" | string => {
  if (date === "" || date === null) {
    return "";
  } else {
    return `${format(date, "EEEE", { locale: de })}, den ${format(
      date,
      "dd.MM.yyyy",
      { locale: de }
    )}`;
  }
};

export const addDaysToDate = (
  date: Date | "" | null,
  days: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return addDays(date, days);
  }
};

export const addWeeksToDate = (
  date: Date | "" | null,
  weeks: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return addWeeks(date, weeks);
  }
};

export const substractWeeksFromDate = (
  date: Date | "" | null,
  weeks: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return subWeeks(date, weeks);
  }
};

export const substractDaysFromDate = (
  date: Date | "" | null,
  days: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return subDays(date, days);
  }
};

export const postlaufzeit = (
  GesellschafterAuchImAusland: string,
  versandart: string
): number => {
  if (
    versandart === versendungsarten[2].value ||
    versandart === versendungsarten[3].value
  ) {
    return postlaufzeiten.email;
  } else if (GesellschafterAuchImAusland === "nein") {
    return postlaufzeiten.postOderEinschreibenInland;
  } else if (GesellschafterAuchImAusland === "ja") {
    return postlaufzeiten.postOderEinschreibenAusland;
  } else {
    return 0;
  }
};
