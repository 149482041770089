/* eslint-disable i18next/no-literal-string */
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { AnyObject } from "@nvon/baseline";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  bemessungszeiträume,
  bestehenszeiträume,
} from "../fristenrechnerConstants";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const SecondQuestion = ({
  buttonSwitch,
  jahresgehaltJaNein,
  handleJahresgehaltJaNein,
  vergütungBemessungszeitraum,
  handleVergütungBemessungszeitraum,
  arbeitsverhältnisBestehenZeitraum,
  handleArbeitsverhältnisBestehenZeitraum,
}: {
  buttonSwitch: boolean;
  jahresgehaltJaNein: string;
  handleJahresgehaltJaNein: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  vergütungBemessungszeitraum: number | number[];
  handleVergütungBemessungszeitraum: (
    event: ChangeEvent<AnyObject>,
    value: number | number[]
  ) => void;
  arbeitsverhältnisBestehenZeitraum: number | number[];
  handleArbeitsverhältnisBestehenZeitraum: (
    event: ChangeEvent<AnyObject>,
    value: number | number[]
  ) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      {buttonSwitch === false ? (
        <>
          <div>
            <Typography className={classes.additionsWrapper}>
              <span>Ist im Anstellungsvertrag ein Jahresgehalt angegeben?</span>

              <Tooltip
                title={t(
                  "fristenrechnerKündigungGf.tooltipSecondQuestionBemessungszeitraumVergütung"
                )}
              >
                <HelpOutline className={classes.tooltipIcon} />
              </Tooltip>
            </Typography>

            <FormControl>
              <RadioGroup
                row={true}
                className={classes.form}
                aria-labelledby="jahresgehalt-ja-nein"
                name="jahresgehalt-ja-nein"
                value={jahresgehaltJaNein}
                onChange={handleJahresgehaltJaNein}
              >
                <FormControlLabel
                  value="nein"
                  control={<Radio />}
                  label="Nein"
                />

                <FormControlLabel value="ja" control={<Radio />} label="Ja" />
              </RadioGroup>
            </FormControl>
          </div>

          {jahresgehaltJaNein === "nein" ? (
            <div className={classes.questions}>
              <Typography className={classes.additionsWrapper}>
                In welchen Zeiträumen ist die Vergütung dann bemessen?{" "}
              </Typography>

              <div className={classes.slider}>
                <Slider
                  value={vergütungBemessungszeitraum}
                  onChange={handleVergütungBemessungszeitraum}
                  aria-labelledby="input-slider"
                  marks={bemessungszeiträume}
                  step={null}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className={classes.questions}>
          <Typography className={classes.additionsWrapper}>
            Wie viele Jahre bestand das Arbeitsverhältnis?{" "}
          </Typography>

          <div className={classes.slider}>
            <Slider
              value={arbeitsverhältnisBestehenZeitraum}
              onChange={handleArbeitsverhältnisBestehenZeitraum}
              aria-labelledby="input-slider"
              marks={bestehenszeiträume}
              step={null}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SecondQuestion;
