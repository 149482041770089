/* eslint-disable i18next/no-literal-string */
import { Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFristenrechnerStyles } from "./FristenrechnerStyles";

const FristenrechnerDisclaimer = (): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  const [checkedDisclaimerMore, setCheckedDisclaimerMore] = useState(false);
  return (
    <Alert severity="warning" className={classes.warning}>
      {t("fristenrechner.disclaimer")}

      <br />

      <div className={classes.additionsWrapper}>
        <Switch
          color="primary"
          checked={checkedDisclaimerMore}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCheckedDisclaimerMore(event.target.checked);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />

        {`Typische Fehlerquellen`}
      </div>

      {checkedDisclaimerMore ? (
        <>
          <p>{t("fristenrechnerKündigungGf.disclaimerMore")}</p>

          <ul>
            <li>
              <p>{t("fristenrechnerKündigungGf.disclaimerMore1")}</p>
            </li>

            <li>
              <p>{t("fristenrechnerKündigungGf.disclaimerMore2")}</p>
            </li>

            <li>
              <p>{t("fristenrechnerKündigungGf.disclaimerMore3")}</p>
            </li>
          </ul>
        </>
      ) : (
        ""
      )}
    </Alert>
  );
};

export default FristenrechnerDisclaimer;
