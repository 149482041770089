/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@material-ui/core";
import { FlashOn, MarkunreadMailbox } from "@material-ui/icons";
import { TimelineConnector } from "@material-ui/lab";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  bestehenszeiträume,
  fristenp621BGB,
  fristenp622BGB,
} from "../fristenrechnerConstants";
import {
  formatDate,
  formatVersammlungstag,
} from "../fristenrechnerHelperFunctions/dateCalculations";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";
import { ErgebnisTimelineProps } from "./ErgebnisTimelineInterfaces";

const ErgebnisTimeline = ({
  buttonSwitch,
  chosenKündigungZugangstag,
  dateWirksamwerdenMitAblauf,
  jahresgehaltJaNein,
  vergütungBemessungszeitraum,
  arbeitsverhältnisBestehenZeitraum,
}: ErgebnisTimelineProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useFristenrechnerStyles();
  return (
    <div>
      <Box className={classes.containerSide}>
        <Typography>
          <strong>Welche Kündigungsfrist gilt?</strong>
        </Typography>

        <Typography>
          {buttonSwitch === false
            ? jahresgehaltJaNein === "ja" || vergütungBemessungszeitraum === 100
              ? fristenp621BGB.p621Nr4
              : jahresgehaltJaNein === "nein" &&
                vergütungBemessungszeitraum === 66
              ? fristenp621BGB.p621Nr3
              : jahresgehaltJaNein === "nein" &&
                vergütungBemessungszeitraum === 33
              ? fristenp621BGB.p621Nr2
              : jahresgehaltJaNein === "nein" &&
                vergütungBemessungszeitraum === 0
              ? fristenp621BGB.p621Nr1
              : ""
            : bestehenszeiträume[0] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[0].value
            ? fristenp622BGB.p622Abs1.text
            : bestehenszeiträume[1] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[1].value
            ? fristenp622BGB.p622Abs2Nr1.text
            : bestehenszeiträume[2] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[2].value
            ? fristenp622BGB.p622Abs2Nr2.text
            : bestehenszeiträume[3] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[3].value
            ? fristenp622BGB.p622Abs2Nr3.text
            : bestehenszeiträume[4] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[4].value
            ? fristenp622BGB.p622Abs2Nr4.text
            : bestehenszeiträume[5] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[5].value
            ? fristenp622BGB.p622Abs2Nr5.text
            : bestehenszeiträume[6] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[6].value
            ? fristenp622BGB.p622Abs2Nr6.text
            : bestehenszeiträume[7] &&
              arbeitsverhältnisBestehenZeitraum === bestehenszeiträume[7].value
            ? fristenp622BGB.p622Abs2Nr7.text
            : ""}
        </Typography>
      </Box>

      {vergütungBemessungszeitraum === 33 ||
      vergütungBemessungszeitraum === 0 ? (
        ""
      ) : (
        <Timeline position="right" className={classes.timeline}>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", py: "24px", px: 2 }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <strong>{formatDate(chosenKündigungZugangstag)}</strong>

              <br />
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot color="primary">
                <MarkunreadMailbox />
              </TimelineDot>

              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "24px", px: 2 }}>
              <Typography variant="h6" component="span">
                <strong>
                  {t("fristenrechnerKündigungGf.chosenDateOfReceipt")}
                </strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", py: "24px", px: 2 }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              <strong>
                <div>
                  {formatVersammlungstag(dateWirksamwerdenMitAblauf)}, 24:00 Uhr
                </div>
              </strong>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineConnector />

              <TimelineDot color="primary">
                <FlashOn />
              </TimelineDot>
            </TimelineSeparator>

            <TimelineContent sx={{ py: "24px", px: 2 }}>
              <Typography variant="h6" component="span">
                <strong>
                  {t("fristenrechnerKündigungGf.effectiveDateOfTermination")}
                </strong>
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}
    </div>
  );
};

export default ErgebnisTimeline;
