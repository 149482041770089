/* eslint-disable i18next/no-literal-string */
import { Tooltip, Typography } from "@material-ui/core";
import { HelpOutline, Settings, Work } from "@material-ui/icons";
import { Button } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export interface ButtonVersendungstagVersammlungsTagProps {
  countingFromDayOfVersammlung: boolean;
  setToCountingFromVersendung: () => void;
  setToCountingFromVersammlung: () => void;
}

const ButtonVersendungstagVersammlungstag = ({
  countingFromDayOfVersammlung,
  setToCountingFromVersendung,
  setToCountingFromVersammlung,
}: ButtonVersendungstagVersammlungsTagProps): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      <Typography className={classes.additionsWrapper}>
        <span>
          Wie ist der Geschäftsführer-Anstellungsvertrag zu qualifizieren?
        </span>

        <Tooltip
          title={t(
            "fristenrechnerKündigungGf.tooltipQualifizierzungAnstellungsvertrag"
          )}
        >
          <HelpOutline className={classes.tooltipIcon} />
        </Tooltip>
      </Typography>

      {countingFromDayOfVersammlung === false ? (
        <div className={classes.form}>
          <Button
            variant="contained"
            onClick={setToCountingFromVersendung}
            startIcon={<Work />}
          >
            Dienstvertrag (Standard)
          </Button>

          <Button
            variant="outlined"
            onClick={setToCountingFromVersammlung}
            startIcon={<Settings />}
          >
            Arbeitsvertrag
          </Button>
        </div>
      ) : (
        <div className={classes.form}>
          <Button
            variant="outlined"
            onClick={setToCountingFromVersendung}
            startIcon={<Work />}
          >
            Dienstvertrag (Standard)
          </Button>

          <Button
            variant="contained"
            onClick={setToCountingFromVersammlung}
            startIcon={<Settings />}
          >
            Arbeitsvertrag
          </Button>
        </div>
      )}
    </div>
  );
};

export default ButtonVersendungstagVersammlungstag;
