import { useTheme } from "@material-ui/core";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";

export const FristenrechnerKündigungGfPageHeader = (
  props: DefaultPageProps
): JSX.Element => {
  const theme = useTheme();
  const title = props.page?.title || "";

  return (
    <RVOContainer
      fullWidth={true}
      background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
      noPaddingTop={false}
      paddingBottom={true}
    >
      <VTSUnderlinedTitle
        titleRaw={title || ""}
        typographyProps={{
          variant: "h1",
          style: {
            width: "100%",
            textAlign: "center",
            marginBottom: theme.spacing(6),
          },
        }}
        component="h1"
      />
    </RVOContainer>
  );
};
