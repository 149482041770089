import { mapGenericContent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import FristenrechnerDisclaimer from "./FristenrechnerDisclaimer";
import { useFristenrechnerStyles } from "./FristenrechnerStyles";

export const FristenrechnerKündigungGfPageDescription = (
  props: DefaultPageProps
): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const content = props.page?.content || "";

  return (
    <RVOContainer
      paddingBottom={true}
      noPaddingTop={true}
      className={classes.description}
    >
      <FristenrechnerDisclaimer />

      {replaceHtmlWithFunctionComponent(content, mapGenericContent)}
    </RVOContainer>
  );
};
